// eslint-disable-next-line
import { UserLayout, BasicLayout, BlankLayout } from '@/layouts'
import { bxAnaalyse } from '@/core/icons'
import storage from 'store'

const RouteView = {
  name: 'RouteView',
  render: h => h('router-view')
}

const tenantId = storage.get('tenantId')
console.log(tenantId)

export const asyncRouterMap = [
  {
    path: '/',
    name: 'index',
    component: BasicLayout,
    meta: { title: '首页' },
    redirect: '/project/list',
    children: [
      // dashboard
      {
        path: '/project',
        name: 'project',
        redirect: '/project/list',
        component: RouteView,
        meta: { title: '评估管理', keepAlive: true, icon: bxAnaalyse },
        children: [
          {
            path: '/project/list',
            name: 'projectList',
            component: () => import('@/views/project/list'),
            meta: { title: '评估项目', keepAlive: true }
          },
          {
            path: '/project/edit',
            name: 'projectEdit',
            component: () => import('@/views/project/edit'),
            meta: { title: '新建项目', keepAlive: true }
          },
          {
            path: '/answer/list/:projectId([1-9]\\d*)?',
            name: 'answerList',
            hidden: true,
            component: () => import('@/views/answer/list'),
            meta: { title: '回答列表', keepAlive: true }
          }
        ]
      },
      {
        path: '/user',
        name: 'user',
        redirect: '/user/list',
        component: RouteView,
        meta: { title: '用户管理', keepAlive: true, icon: bxAnaalyse },
        children: [
          {
            path: '/user/tenant',
            name: 'tenant',
            component: () => import('@/views/user/tenant'),
            hidden: tenantId !== 1,
            meta: { title: '二级账号', keepAlive: true }
          },
          {
            path: '/user/list',
            name: 'userList',
            component: () => import('@/views/user/list'),
            meta: { title: '用户列表', keepAlive: true }
          },
          {
            path: '/user/edit',
            name: 'userEdit',
            component: () => import('@/views/user/edit'),
            meta: { title: '个人中心', keepAlive: true }
          }
        ]
      },
      {
        path: '/statistic',
        name: 'statistic',
        redirect: '/statistic/analyse',
        hidden: true,
        component: RouteView,
        meta: { title: '数据统计', keepAlive: true, icon: bxAnaalyse },
        children: [
          {
            path: '/statistic/analyse',
            name: 'analyse',
            component: () => import('@/views/statistic/analyse'),
            meta: { title: '统计分析', keepAlive: true }
          }
        ]
      }
    ]
  },
  {
    path: '*',
    redirect: '/404',
    hidden: true
  }
]

/**
 * 基础路由
 * @type { *[] }
 */
export const constantRouterMap = [
  {
    path: '/user',
    component: UserLayout,
    redirect: '/user/login',
    hidden: true,
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/Login')
      },
      {
        path: 'register',
        name: 'register',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/Register')
      },
      {
        path: 'register-result',
        name: 'registerResult',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/RegisterResult')
      },
      {
        path: 'recover',
        name: 'recover',
        component: undefined
      }
    ]
  },

  {
    path: '/404',
    component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/404')
  },
  {
    path: '/assessment/:id([1-9]\\d*)?',
    name: 'assessment',
    component: () => import(/* webpackChunkName: "fail" */ '@/views/project/detail')
  }
]
